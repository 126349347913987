// Mobile modal
.mobile {
    @apply relative overflow-y-scroll overflow-x-hidden opacity-0 absolute h-0 w-full bg-navy-100 z-30;
}

.mobile.transition {
    @apply transition-opacity duration-300 ease-in-out;
}

.mobile.active {
    height: calc(100vh - 84px);
    @apply opacity-100;
}


// Mobile nav
.mobile-nav__link {
    font-size: 18px;
    @apply flex px-6 py-3 items-center border-b border-navy-300 font-bold text-navy-700;
}

.mobile-nav__link:hover {
    @apply bg-navy-300;
}

.mobile-nav__item--active .mobile-nav__link {
    @apply bg-navy-300;
}

.mobile-nav__item--children > .mobile-nav__link {
    @apply relative;
    &:after {
        width: 72px;
        @apply absolute flex justify-center items-center top-0 right-0 bottom-0 bg-navy-200;
        content: url('data:image/svg+xml; utf8, <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.82324 6.56641C6.99902 6.39062 6.99902 6.14453 6.82324 5.96875L1.69043 0.765625C1.51465 0.589844 1.2334 0.589844 1.09277 0.765625L0.389648 1.46875C0.213867 1.60938 0.213867 1.89062 0.389648 2.06641L4.53809 6.25L0.389648 10.4688C0.213867 10.6445 0.213867 10.8906 0.389648 11.0664L1.09277 11.7695C1.2334 11.9453 1.51465 11.9453 1.69043 11.7695L6.82324 6.56641Z" fill="%23133D59"/></svg>');
    }
}

.mobile-search {
    @apply flex flex-wrap px-6 py-3 items-center border-b border-navy-300;
}

.mobile-search__input {
    @apply bg-transparent text-navy-700;
    &::placeholder {
        @apply text-navy-700 opacity-100;
    }
    &:focus {
        outline: none;
    }
}

.mobile-nav__panel .mobile-nav__link {
    font-size: 16px;
    @apply font-normal;
}

.mobile-nav__panel .mobile-nav__item--children > .mobile-nav__link {
    &:after {
        @apply bg-transparent;
    }
}

.mobile-nav__panel .mobile-nav__link--title {
    font-size: 18px;
    @apply font-bold;
}

.mobile-nav__link--back {
    &:before {
        @apply mr-3;
        content: url('data:image/svg+xml; utf8, <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.139648 5.96875C-0.0361328 6.14453 -0.0361328 6.39062 0.139648 6.56641L5.30762 11.7695C5.44824 11.9453 5.72949 11.9453 5.90527 11.7695L6.57324 11.0664C6.74902 10.8906 6.74902 10.6445 6.57324 10.4688L2.4248 6.25L6.57324 2.06641C6.74902 1.89062 6.74902 1.60938 6.57324 1.46875L5.90527 0.765625C5.72949 0.589844 5.44824 0.589844 5.30762 0.765625L0.139648 5.96875Z" fill="%23133D59"/></svg>');
    }
}

.mobile-nav__item--children .mobile-nav__panel {
    .mobile-nav__item {
        &--active {
            .mobile-nav__link {
                @apply bg-navy-100;
            }
        }
    }
}

.mobile-nav__panel {
    height: calc(100vh - 84px);
    transform: translateX(100%);
    @apply overflow-hidden absolute top-0 left-0 w-full bg-navy-100 transition-transform duration-500 ease-in-out;
}

.mobile-nav__panel.active {
    transform: translateX(0%);
    @apply overflow-y-scroll z-40;
}
