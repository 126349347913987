// Carousel block

.carousel__item {
    max-width: 260px;
    @apply pr-6;
    @screen md {
        max-width: 320px;
    }
    @screen lg {
        max-width: 450px;
        @apply w-1/3 pr-12;
    }
}

.carousel__tile {
    @apply block relative;
}

.carousel__title {
    background: linear-gradient(0deg, #092031 0%, rgba(255,255,255,0) 100%);
    top: 60%;
    @apply absolute bottom-0 left-0 w-full px-6 pb-6 flex items-end;
    @screen md {
        @apply px-8 pb-10;
    }
}

.carousel__title span {
    @apply relative inline-block;
}

.carousel__title span:after {
    content: '';
    margin-top: 2px;
    height: 2px;
    width: 0px;
    transition-property: width;
    @apply block bg-white duration-500 ease-in-out;
}

.carousel__tile:hover .carousel__title span:after {
    width: 100%;
}

.carousel__prev,
.carousel__next {
    height: 48px;
    width: 48px;
    @apply inline-flex justify-center items-center bg-navy-800 rounded-full transition duration-300 ease-in-out;
    @screen lg {
        height: 72px;
        width: 72px;
    }
    &:hover {
        @apply bg-navy-600;
    }
    &:focus {
        outline: none;
    }
    &.swiper-button-disabled {
        @apply opacity-25;
    }
}

.carousel__prev:after {
    width: 21px;
    height: 22px;
    content: url('data:image/svg+xml; charset=utf-8, <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.875 21.3125C10.7344 21.4062 10.5938 21.4531 10.4531 21.4531C10.2656 21.4531 10.1719 21.4062 10.0781 21.3125L0.1875 11.375C0.046875 11.2812 0 11.1875 0 11C0 10.8594 0.046875 10.7188 0.1875 10.625L10.0781 0.6875C10.1719 0.59375 10.2656 0.546875 10.4531 0.546875C10.5938 0.546875 10.7344 0.59375 10.875 0.6875L11.2031 1.0625C11.2969 1.15625 11.3438 1.29688 11.3438 1.4375C11.3438 1.625 11.2969 1.76562 11.2031 1.85938L2.8125 10.2031H20.4375C20.5781 10.2031 20.7188 10.2969 20.8125 10.3906C20.9062 10.4844 21 10.625 21 10.7656V11.2344C21 11.4219 20.9062 11.5625 20.8125 11.6562C20.7188 11.75 20.5781 11.7969 20.4375 11.7969H2.8125L11.2031 20.1406C11.2969 20.2812 11.3438 20.4219 11.3438 20.5625C11.3438 20.75 11.2969 20.8438 11.2031 20.9375L10.875 21.3125Z" fill="white"/></svg>');
}

.carousel__next:after {
    width: 21px;
    height: 22px;
    content: url('data:image/svg+xml; charset=utf-8,<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.125 0.6875C10.2188 0.59375 10.3594 0.546875 10.5469 0.546875C10.6875 0.546875 10.8281 0.59375 10.9219 0.6875L20.8125 10.625C20.9062 10.7188 21 10.8594 21 11C21 11.1875 20.9062 11.2812 20.8125 11.375L10.9219 21.3125C10.8281 21.4062 10.6875 21.4531 10.5469 21.4531C10.3594 21.4531 10.2188 21.4062 10.125 21.3125L9.79688 20.9375C9.70312 20.8438 9.65625 20.75 9.65625 20.5625C9.65625 20.4219 9.70312 20.2812 9.79688 20.1406L18.1875 11.7969H0.5625C0.375 11.7969 0.234375 11.75 0.140625 11.6562C0.046875 11.5625 0 11.4219 0 11.2344V10.7656C0 10.625 0.046875 10.4844 0.140625 10.3906C0.234375 10.2969 0.375 10.2031 0.5625 10.2031H18.1875L9.79688 1.85938C9.70312 1.76562 9.65625 1.625 9.65625 1.4375C9.65625 1.29688 9.70312 1.15625 9.79688 1.0625L10.125 0.6875Z" fill="white"/></svg>');
}


// Related products carousel

.carousel__item--alt {
    max-width: 280px;
    @apply px-3;
    @screen md {
        max-width: 320px;
    }
    @screen lg {
        max-width: 360px;
        @apply w-1/4 text-center;
    }
}

.carousel__item--alt3 {
	max-width: 260px;
	@apply pr-7;
	@screen md {
		max-width: 320px;
	}
	@screen lg {
		max-width: 450px;
		@apply w-1/3 pr-12;
	}
}

.carousel__item--alt .h5,.carousel__item--alt3 .h5 {
    @apply inline-block border-b border-transparent transition duration-300 ease-in-out;
}

.carousel__item--alt a:hover .h5, .carousel__item--alt3 a:hover .h5  {
    @apply border-white;
}
