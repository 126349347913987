// Gallery

.gallery__prev,
.gallery__next {
    height: 48px;
    width: 48px;
    @apply inline-flex justify-center items-center bg-white rounded-full transition duration-300 ease-in-out;
    @screen lg {
        height: 64px;
        width: 64px;
    }
    &:not(.swiper-button-disabled):hover {
        @apply bg-navy-600 cursor-pointer;
    }
    &:not(.swiper-button-disabled):hover svg {
        @apply fill-white;
    }
    &:focus {
        outline: none;
    }
    svg {
        @apply fill-navy-700;
    }
    &.swiper-button-disabled {
        @apply opacity-0;
    }
}

.gallery-thumbnails .swiper-slide {
    max-width: 130px;
    transition-property: border;
    @apply border-2 border-transparent mr-4 duration-300 ease-in-out;
    @screen lg {
        max-width: 180px;
        @apply mr-6;
    }
}

.gallery-thumbnails .swiper-slide:hover {
    @apply border-navy-400 cursor-pointer;
}

.gallery-thumbnails .swiper-slide-thumb-active {
    @apply border-navy-400;
}
