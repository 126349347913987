// App
// Includes styles that are likely to be changed/compiled often

/* purgecss start ignore */
@import "partials/animation";
@import "partials/header";
@import "partials/mega-menu";
@import "partials/mobile-menu";
@import "partials/footer";
@import "sections/accordion";
@import "sections/carousel";
@import "sections/content";
@import "sections/gallery";
@import "sections/image";
@import "sections/list";
/* purgecss end ignore */
