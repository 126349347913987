// Header
.header {
    @apply relative py-6 bg-white border-b-4 border-navy-200 z-30;
    @screen lg {
        @apply pt-4 pb-0;
    }
}


// Top nav - desktop
.top-nav__item {
    @apply inline-block text-navy-700;
}

.top-nav__link {
    font-size: 16px;
    @apply inline-block px-3 py-2;
}

.top-nav__item:hover .top-nav__link {
    @apply bg-navy-200;
}

.top-nav__link--active {
    @apply text-navy-400;
}

.sub-nav {
    width: 200px;
    max-height: 0;
    @apply absolute overflow-hidden opacity-0 bg-navy-200 transition-opacity duration-300 ease-in-out z-50;
}

.main-nav__item--items:hover .sub-nav,
.top-nav__item:hover .sub-nav {
    max-height: 400px;
    @apply opacity-100 py-2;
}

.main-nav__item--items {
    @apply text-navy-700;
}

.sub-nav__link {
    font-size: 16px;
    @apply block px-4 py-2;
}

.sub-nav__link--active {
    @apply underline;
}

.sub-nav__link:hover {
    @apply bg-navy-300;
}


// Main nav - desktop
.main-nav {
    @apply pt-4;
}

.main-nav__item {
    @apply inline-block;

    .sub-nav {
        min-width: 180px;
        @apply w-auto;
    }
}

.main-nav__item--children .main-nav__link {
    &:after {
        content: url('data:image/svg+xml; utf8, <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.34375 7.48438H5.30859C5.37891 7.55469 5.48438 7.58984 5.625 7.58984C5.73047 7.58984 5.83594 7.55469 5.94141 7.48438L11.1445 2.31641C11.2148 2.24609 11.25 2.14062 11.25 2.03516C11.25 1.92969 11.2148 1.82422 11.1445 1.71875L10.4414 1.01562C10.3359 0.945312 10.2305 0.910156 10.125 0.910156C9.98438 0.910156 9.91406 0.945312 9.84375 1.01562L5.625 5.19922L1.40625 1.01562C1.33594 0.945312 1.23047 0.910156 1.125 0.910156C0.984375 0.910156 0.878906 0.945312 0.808594 1.01562L0.105469 1.71875C0.0351562 1.82422 0 1.92969 0 2.03516C0 2.14062 0.0351562 2.24609 0.140625 2.31641L5.34375 7.48438Z" fill="%23133D59"/></svg>');
        @apply ml-2;
    }
}

.main-nav__link {
    @apply inline-block px-4 py-3 font-bold text-navy-700;
}

.mobile-nav__item .current {
    @apply text-navy-400;
}

.main-nav__item:hover .main-nav__link,
.main-nav__link.current {
    @apply bg-navy-200 text-navy-700;
}


// Hamburger icon
.nav-icon {
	width: 30px;
	height: 24px;
    @apply relative inline-block cursor-pointer;
}

.nav-icon__inner,
.nav-icon__inner:before,
.nav-icon__inner:after {
    content: '';
    height: 4px;
    width: 100%;
    @apply absolute block left-0 bg-navy-700;
}

.nav-icon__inner {
    top: 0;
    .nav-icon.active & {
        background-color: transparent;
    }
}

.nav-icon__inner:before {
	top: 10px;
    .nav-icon.active & {
        top: 10px;
        transform: rotate(45deg);
    }
}

.nav-icon__inner:after {
	top: 20px;
    .nav-icon.active & {
        top: 10px;
        transform: rotate(-45deg);
    }
}

.breadcrumbs {
    @apply flex items-center justify-start;
}
// Breadcrumbs
.breadcrumbs__item {
    @apply inline-block mr-3;
    @screen lg {
        font-size: 16px;
    }
}

.breadcrumbs__item + .breadcrumbs__item {
    &:before {
        content: url('data:image/svg+xml; utf8, <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.14062 4.96875V4.99609C5.19531 4.94141 5.22266 4.85938 5.22266 4.75C5.22266 4.66797 5.19531 4.58594 5.14062 4.50391L1.12109 0.457031C1.03906 0.402344 0.957031 0.375 0.875 0.375C0.792969 0.375 0.710938 0.402344 0.65625 0.457031L0.109375 1.00391C0.0546875 1.08594 0.0273438 1.16797 0.0273438 1.25C0.0273438 1.35938 0.0546875 1.41406 0.109375 1.46875L3.36328 4.75L0.109375 8.03125C0.0546875 8.08594 0.0273438 8.16797 0.0273438 8.25C0.0273438 8.35938 0.0546875 8.44141 0.109375 8.49609L0.65625 9.04297C0.710938 9.09766 0.792969 9.125 0.875 9.125C0.957031 9.125 1.03906 9.09766 1.12109 9.01562L5.14062 4.96875Z" fill="white" /></svg>');
        @apply mr-3;
    }
}

@media print {
    .breadcrumbs__item + .breadcrumbs__item {
        &:before {
            content: url('data:image/svg+xml; utf8, <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.14062 4.96875V4.99609C5.19531 4.94141 5.22266 4.85938 5.22266 4.75C5.22266 4.66797 5.19531 4.58594 5.14062 4.50391L1.12109 0.457031C1.03906 0.402344 0.957031 0.375 0.875 0.375C0.792969 0.375 0.710938 0.402344 0.65625 0.457031L0.109375 1.00391C0.0546875 1.08594 0.0273438 1.16797 0.0273438 1.25C0.0273438 1.35938 0.0546875 1.41406 0.109375 1.46875L3.36328 4.75L0.109375 8.03125C0.0546875 8.08594 0.0273438 8.16797 0.0273438 8.25C0.0273438 8.35938 0.0546875 8.44141 0.109375 8.49609L0.65625 9.04297C0.710938 9.09766 0.792969 9.125 0.875 9.125C0.957031 9.125 1.03906 9.09766 1.12109 9.01562L5.14062 4.96875Z" fill="%23133D59" /></svg>');
        }
    }
}

.breadcrumbs__link {
    @apply border-b border-transparent;
}

.breadcrumbs__link:hover {
    @apply border-navy-100;
}

.close-panel-btn {
    &:hover {
        svg {fill: #00A1E0 !important;}
    }
}
