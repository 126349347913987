// Animations

.fade-in {
    @apply opacity-0 transition duration-1000 ease-in-out;
}

@media print {
    .fade-in {
        @apply opacity-100;
    }
}

[data-scroll="in"] {
    .fade-in {
        @apply opacity-100;
    }
}

.slide-left {
    @screen lg {
        transform: translateX(80px);
        @apply transition duration-1000 ease-in-out;
    }
}

[data-scroll="in"] {
    .slide-left {
        @screen lg {
            transform: translateX(0);
        }
    }
}

.slide-right {
    @screen lg {
        transform: translateX(-80px);
        @apply transition duration-1000 ease-in-out;
    }
}

[data-scroll="in"] {
    .slide-right {
        @screen lg {
            transform: translateX(0);
        }
    }
}

[data-scroll-parallax] {
    // transform: translateY(calc(var(--viewport-y) * -2vh));
    // will-change: transform;
    // @apply transition-transform duration-300 ease-linear;
    // @screen lg {
    //     transform: translateY(calc(var(--viewport-y) * -4vh));
    // }
}

@media print {
    // [data-scroll-parallax] {
    //     transform: translateY(0);
    // }
}
