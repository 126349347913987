// Accordions

.accordion {

}

.accordion__item {
	@apply relative border-b-4 border-navy-300;
}

.accordion__title,
.accordion-tabs-link {
	@apply relative flex justify-between items-center py-3 text-navy-700 transition-all duration-300 ease-in-out;
    @screen lg {
        @apply py-4;
    }
    &:after {
        height: 22px;
        width: 21px;
        content: url('data:image/svg+xml; charset=utf-8, <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.75 0.5C19.3594 0.5 19.875 0.734375 20.3438 1.15625C20.7656 1.625 21 2.14062 21 2.75V19.25C21 19.9062 20.7656 20.4219 20.3438 20.8438C19.875 21.3125 19.3594 21.5 18.75 21.5H2.25C1.59375 21.5 1.07812 21.3125 0.65625 20.8438C0.1875 20.4219 0 19.9062 0 19.25V2.75C0 2.14062 0.1875 1.625 0.65625 1.15625C1.07812 0.734375 1.59375 0.5 2.25 0.5H18.75ZM17.25 12.3125V9.6875C17.25 9.54688 17.1562 9.40625 17.0625 9.3125C16.9688 9.21875 16.8281 9.125 16.6875 9.125H12.375V4.8125C12.375 4.67188 12.2812 4.53125 12.1875 4.4375C12.0938 4.34375 11.9531 4.25 11.8125 4.25H9.1875C9 4.25 8.85938 4.34375 8.76562 4.4375C8.67188 4.53125 8.625 4.67188 8.625 4.8125V9.125H4.3125C4.125 9.125 3.98438 9.21875 3.89062 9.3125C3.79688 9.40625 3.75 9.54688 3.75 9.6875V12.3125C3.75 12.5 3.79688 12.6406 3.89062 12.7344C3.98438 12.8281 4.125 12.875 4.3125 12.875H8.625V17.1875C8.625 17.375 8.67188 17.5156 8.76562 17.6094C8.85938 17.7031 9 17.75 9.1875 17.75H11.8125C11.9531 17.75 12.0938 17.7031 12.1875 17.6094C12.2812 17.5156 12.375 17.375 12.375 17.1875V12.875H16.6875C16.8281 12.875 16.9688 12.8281 17.0625 12.7344C17.1562 12.6406 17.25 12.5 17.25 12.3125Z" fill="%23133D59"/></svg>');
    }
}

.accordion__title:hover,
.accordion-tabs-link:hover {
    @apply text-navy-500;
}

.accordion__title:hover {
    @apply bg-navy-200;
}

.accordion__title.is-active:hover {
    @apply bg-navy-100;
}

.accordion__title.is-active,
.accordion-tabs-link.is-active {
    @apply text-navy-500;
    &:after {
        content: url('data:image/svg+xml; charset=utf-8, <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.75 0.5C19.3594 0.5 19.875 0.734375 20.3438 1.15625C20.7656 1.625 21 2.14062 21 2.75V19.25C21 19.9062 20.7656 20.4219 20.3438 20.8438C19.875 21.3125 19.3594 21.5 18.75 21.5H2.25C1.59375 21.5 1.07812 21.3125 0.65625 20.8438C0.1875 20.4219 0 19.9062 0 19.25V2.75C0 2.14062 0.1875 1.625 0.65625 1.15625C1.07812 0.734375 1.59375 0.5 2.25 0.5H18.75ZM4.3125 12.875H16.6875C16.8281 12.875 16.9688 12.8281 17.0625 12.7344C17.1562 12.6406 17.25 12.5 17.25 12.3125V9.6875C17.25 9.54688 17.1562 9.40625 17.0625 9.3125C16.9688 9.21875 16.8281 9.125 16.6875 9.125H4.3125C4.125 9.125 3.98438 9.21875 3.89062 9.3125C3.79688 9.40625 3.75 9.54688 3.75 9.6875V12.3125C3.75 12.5 3.79688 12.6406 3.89062 12.7344C3.98438 12.8281 4.125 12.875 4.3125 12.875Z" fill="%23127CBA"/></svg>');
    }
}

.accordion__content {
    max-height: 0;
    @apply overflow-hidden opacity-0 transition-all duration-300 ease-in-out;

    .row {
        &:last-child {
            .column {
                @apply mb-0;
            }
        }
    }
}

.accordion__content.is-active {
    max-height: 9999px;
    @apply opacity-100 pb-3;
    @screen lg {
        @apply pb-6;
    }
}


// Accordion tabs

.tabs-menu {
    @apply w-full flex flex-wrap justify-center border-b-2 border-navy-200;
}

.tabs-menu__item {
    @apply w-full text-center;
    @screen md {
        max-width: 300px;
        @apply w-1/3;
    }
}

.tabs-menu__link {
    @apply block py-3 mx-1 font-bold text-xxl text-navy-700;
}

.tabs-menu__link.is-active,
.tabs-menu__link:hover {
    @apply bg-navy-200;
}

.accordion-tabs-link {
    @apply bg-navy-200 border-t-4 border-navy-300;
}

.accordion-tabs-content {
    max-height: 0;
    transition-property: max-height, opacity;
    @apply overflow-hidden opacity-0 duration-500 ease-in-out;

}

.accordion-tabs-content.is-active {
    max-height: 10000px;
    @apply opacity-100;
}


// Team accordion

.team__title h3 {
	@apply relative flex justify-between transition-all duration-300 ease-in-out;
    &:after {
        height: 18px;
        width: 18px;
        content: url('data:image/svg+xml; charset=utf-8, <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.625 7.875C17.7188 7.875 17.7656 7.92188 17.8594 8.01562C17.9531 8.10938 18 8.15625 18 8.25V9.75C18 9.84375 17.9531 9.9375 17.8594 10.0312C17.7656 10.125 17.7188 10.125 17.625 10.125H10.125V17.625C10.125 17.7188 10.0781 17.8125 9.98438 17.9062C9.89062 18 9.84375 18 9.75 18H8.25C8.15625 18 8.0625 18 7.96875 17.9062C7.875 17.8125 7.875 17.7188 7.875 17.625V10.125H0.375C0.28125 10.125 0.1875 10.125 0.09375 10.0312C0 9.9375 0 9.84375 0 9.75V8.25C0 8.15625 0 8.10938 0.09375 8.01562C0.1875 7.92188 0.28125 7.875 0.375 7.875H7.875V0.375C7.875 0.28125 7.875 0.234375 7.96875 0.140625C8.0625 0.046875 8.15625 0 8.25 0H9.75C9.84375 0 9.89062 0.046875 9.98438 0.140625C10.0781 0.234375 10.125 0.28125 10.125 0.375V7.875H17.625Z" fill="%23133D59"/></svg>');
    }
}

.team__title:hover h3 {
    @apply text-navy-500;
}

.team__title.is-active h3 {
    &:after {
        content: url('data:image/svg+xml; charset=utf-8, <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.625 0.875C17.7188 0.875 17.7656 0.921875 17.8594 1.01562C17.9531 1.10938 18 1.15625 18 1.25V2.75C18 2.84375 17.9531 2.9375 17.8594 3.03125C17.7656 3.125 17.7188 3.125 17.625 3.125H0.375C0.28125 3.125 0.1875 3.125 0.09375 3.03125C0 2.9375 0 2.84375 0 2.75V1.25C0 1.15625 0 1.10938 0.09375 1.01562C0.1875 0.921875 0.28125 0.875 0.375 0.875H17.625Z" fill="%23133D59"/></svg>');
    }
}

.team__content {
    max-height: 0;
    @apply overflow-hidden opacity-0 transition-all duration-300 ease-in-out;
}

.team__content.is-active {
    max-height: 1000px;
    @apply opacity-100;
}

// Accordion 

.accordion-announcements {
    .column {
        a {
           // @apply text-navy-700;
        }
    }
}




