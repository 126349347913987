// Mega menu

.mega-menu-wrapper {
    width: calc(100vw - 64px);
    @apply hidden absolute bg-navy-200 z-40 left-0;

     @screen lg { 
        min-height: 500px;
     }

    @media only screen and (min-width: 1284px) {
        width: 1220px;
    }

    > .row {
        min-height: 500px;
    }
}

.mega-menu-wrapper.active {
    @apply block;
}

.mega-menu__link {
    font-size: 16px;
    line-height: 1.16;
    @apply block px-8 py-2 text-navy-700;
}

.mega-menu__link--active {
    @apply underline;
}

.mega-menu__item:hover > .mega-menu__link,
.mega-menu__link.active {
    @apply bg-navy-300;
}

.mega-menu__item--children > .mega-menu__link {
    @apply flex justify-between;
    &:after {
        content: url('data:image/svg+xml; utf8, <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.875 5.25V5.28125C5.9375 5.21875 5.96875 5.125 5.96875 5C5.96875 4.90625 5.9375 4.8125 5.875 4.71875L1.28125 0.09375C1.1875 0.03125 1.09375 0 1 0C0.90625 0 0.8125 0.03125 0.75 0.09375L0.125 0.71875C0.0625 0.8125 0.03125 0.90625 0.03125 1C0.03125 1.125 0.0625 1.1875 0.125 1.25L3.84375 5L0.125 8.75C0.0625 8.8125 0.03125 8.90625 0.03125 9C0.03125 9.125 0.0625 9.21875 0.125 9.28125L0.75 9.90625C0.8125 9.96875 0.90625 10 1 10C1.09375 10 1.1875 9.96875 1.28125 9.875L5.875 5.25Z" fill="%23133D59"/></svg>');
    }
}

.mega-menu__item--children {
    &:hover {
        .mega-menu-panel {
            @apply opacity-100 visible;
        }
    }
}

.mega-menu-feature {
    h6 {
        @apply border-b border-transparent;
    }
}

.mega-menu-feature:hover {
    h6 {
        @apply border-white;
    }
}

.mega-menu-panel {
    transition: all 0.3s;
    @apply opacity-0 invisible flex absolute top-0 right-0 h-full flex-wrap w-3/4 bg-navy-800;
}

// .mega-menu-panel.active {
//     @apply flex;
// }
