

// Feature block

.feature__image {
    @apply relative w-full z-20;
    @screen lg {
        @apply w-1/2;
    }
}

.feature__image img {
    @apply object-cover;
    @screen lg {
        min-height: 620px;
    }
}

.feature__content {
    @apply relative w-full z-10;
    @screen lg {
        @apply flex flex-wrap content-start w-1/2;
    }
}

.feature--left .feature__content {
    // @media only screen and (min-width: 1284px) {
    //     width: calc((1284px / 2));
    // }
    @media only screen and (min-width: 1284px) {    // Container width
        padding-right: calc((100% - 1220px) / 2);
    }
}

.feature--right .feature__image {
    @screen lg {
        @apply order-2;
    }
}

.feature--right .feature__content {
    @screen lg {
        @apply order-1 justify-end;
    }
    @media only screen and (min-width: 1284px) {    // Container width
        padding-left: calc((100% - 1220px) / 2);
    }
}


// Downloads list
.download-list__item:first-child {
    @apply border-t border-navy-700;
}

.download-list__item {
    @apply border-b border-navy-700;
}

.download-list__link {
    @apply flex justify-between items-center px-4 py-2 font-bold;
    @screen lg {
        @apply px-6 py-3;
    }
}

.download-list__link:hover {
    @apply bg-navy-700 text-white;
}

.download-list__icon:before {
    @screen lg {
        content: 'Download';
        font-size: 12px;
        @apply font-headings uppercase text-white;
    }
}

.download-list__icon svg {
    @apply inline-block ml-6 fill-navy-700;
}

.download-list__link:hover .download-list__icon svg {
    @apply fill-white;
}


// Case study block

.articles {
    @apply relative;
    &:before {
        content: '';
        height: 14%;
        @apply absolute top-0 left-0 right-0 bg-white z-10;
        @screen md {
            height: 22%;
        }
        @screen lg {
            height: 34%;
        }
        @screen xl {
            height: 40%;
        }
    }
}

.article {
    @apply flex flex-wrap;
}

.article__tag {
    width: 32px;
    @apply relative;
    @screen lg {
        width: 40px;
    }
}

.article__text {
    font-size: 12px;
    white-space: nowrap;
    @apply inline-block font-headings font-bold uppercase text-navy-400;
    @screen lg {
        font-size: 14px;
    }
}

.article__text--rotate {
    line-height: 1;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    width: 200px;
    top: 200px;
    left: 0px;
    @apply absolute text-right;
}

.article__content {
    width: calc(100% - 32px);
    @screen lg {
        width: calc(100% - 40px);
    }
}

.article__quote {
    @screen md {
        @apply pl-8;
    }
    @screen lg {
        max-width: 520px;
        @apply pl-0;
    }
}


// Logos block

.logo {
    border-radius: 12px;
    @apply px-4 py-6 bg-white border border-navy-200;
}

// Tiles block

.tile {
    @apply block relative;
}

.tile__title {
    background: linear-gradient(0deg, #092031 0%, rgba(255,255,255,0) 100%);
    top: 60%;
    @apply absolute bottom-0 left-0 w-full px-8 py-7 flex items-end;
}


.statistic {
    @apply flex flex-wrap items-center px-5 py-8 font-headings font-bold text-center;
    @screen lg {
        @apply py-18;
    }
    @screen xl {
        @apply px-10;
    }
}

.statistic p {
    font-size: 18px;
    @screen lg {
        font-size: 20px;
    }
    @screen xl {
        font-size: 24px;
    }
}

.statistic p:nth-child(2) {
    @screen xl {
        @apply mt-2;
    }
}

.statistic p:last-child {
    @screen lg {
        font-size: 26px;
    }
    @screen xl {
        font-size: 32px;
    }
}

.statistic strong {
    font-size: 56px;
}


// Links block

.large-button {
    @apply relative overflow-hidden flex items-center mb-2 bg-cream-100 z-0;
}

.large-button .h4 {
    width: calc(100% - 80px);
    @apply px-4;
    @screen md {
        @apply px-6;
    }
}

.large-button:after {
    content: '';
    width: 150%;
    transform: translateX(-115%) skewX(-30deg);
    z-index: -10;
    @apply absolute top-0 left-0 h-full bg-cream-200 bg-opacity-50 transition-transform duration-1000 ease-in-out;
}

.large-button:hover:after {
    transform: translateX(-15%) skewX(-30deg);
}
