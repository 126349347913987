// Footer

.footer {
}

.icon {
    @apply fill-navy-800;
    @screen md {
        @apply fill-grey-800;
    }
}

.icon:hover {
    @apply fill-navy-400;
}
